@tailwind base;
@tailwind components;
@tailwind utilities;


/*

-pink nakala --> #E6007E
-red cahier --> #C41627
-grey nakala --> #A6A2A4
-grey brown nakala --> #544D51


*/





